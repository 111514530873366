import ServerError, { CriticalErrors } from '@/src/const/server-errors'
import { UserStatuses } from '@/src/services/types/payment.tsx'
import { logout, setRedirectUrl } from '@/src/store/slices/authSlice'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

const basePath = process.env?.API
  ? process.env.API
  : process.env.NEXT_PUBLIC_BASE_PATH

const baseQuery = fetchBaseQuery({
  baseUrl: basePath + '/billfold-api/',
  prepareHeaders(headers) {
    if (process.env.BASIC_AUTH_SSR) {
      headers.set('Authorization', process.env.BASIC_AUTH_SSR)
    }

    if (process.env.X_COUNTRY_TOKEN) {
      headers.set('X-Country-Token', process.env.X_COUNTRY_TOKEN)
    }

    return headers
  },
})

const baseQueryCMS = fetchBaseQuery({
  baseUrl: 'https://lucky-dev2.onladv.com' + '/cms/',
  prepareHeaders(headers) {
    if (process.env.BASIC_AUTH_SSR) {
      headers.set('Authorization', process.env.BASIC_AUTH_SSR)
    }
    return headers
  },
})

const baseQueryCloudflare = fetchBaseQuery({
  baseUrl: `https://api.cloudflare.com/client/v4/zones/${process.env.CLOUDFLARE_ZONE_ID}/`,
  prepareHeaders(headers) {
    if (process.env.CLOUDFLARE_PURGE_TOKEN) {
      headers.set('Authorization', process.env.CLOUDFLARE_PURGE_TOKEN)
    }
    return headers
  },
})

const baseQueryNext = fetchBaseQuery({
  baseUrl: basePath + '/api/',
  prepareHeaders(headers) {
    if (process.env.BASIC_AUTH_SSR) {
      headers.set('Authorization', process.env.BASIC_AUTH_SSR)
    }
    return headers
  },
})

const baseQueryFastTrack = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_FAST_TRACK_API + '/api/',
})

const baseQueryRedtiger = fetchBaseQuery({
  baseUrl: 'https://feed-evo-luckycasino00000.redtiger.cash',
})

const baseQueryFastTrackFusionUrl = fetchBaseQuery({
  baseUrl:
    process.env.NEXT_PUBLIC_BRAND_FT_URL +
    '/' +
    process.env.NEXT_PUBLIC_EXPOSERURL,
  /* prepareHeaders(headers) {
    if (process.env.BASIC_AUTH_SSR) {
      headers.set('authtoken', process.env.BASIC_AUTH_SSR)
    }
    return headers
  }, */
})

type ErrorType = {
  error?: string
  errorCode?: number
  params?: { paymentLimit: string } // @TODO create a type
} & UserStatuses

type ResultType = Awaited<ReturnType<typeof baseQuery>>
const logError = (result: ResultType) => {
  if (typeof window === 'undefined' && result.error) {
    // eslint-disable-next-line no-console
    console.log('result', {
      url: result?.meta?.request.url,
      error: result.error,
    })
  }
}

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Record<string, unknown>
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)

  logError(result)

  const resultData = result?.data as ErrorType // @TODO fix me
  const errorCode = resultData?.errorCode

  if (errorCode) {
    if (errorCode === ServerError.NoAvailableSessionException) {
      api.dispatch(logout())
    } else {
      if (errorCode === ServerError.PaymentLimitException) {
        const url = typeof args === 'string' ? args : args.url
        // BE returns 117 for min and max error and daily limits on deposits/ withdraw
        let errorCodeFlote = ServerError.PaymentLimitException
        if (url === 'payment/withdraw') {
          if (resultData?.params?.paymentLimit === 'minAmount') {
            errorCodeFlote = ServerError.PaymentLimitExceptionWithdrawalMin
          } else if (resultData?.params?.paymentLimit === 'maxAmount') {
            errorCodeFlote = ServerError.PaymentLimitExceptionWithdrawalMax
          } else if (
            ['daily', 'weekly', 'monthly'].includes(
              resultData?.params?.paymentLimit || '',
            )
          ) {
            errorCodeFlote = 117.5
          }
        } else if (url === 'payment/confirmDeposit') {
          if (resultData?.params?.paymentLimit === 'minAmount') {
            errorCodeFlote = ServerError.PaymentLimitExceptionDepositMin
          } else if (resultData?.params?.paymentLimit === 'maxAmount') {
            errorCodeFlote = ServerError.PaymentLimitExceptionDepositMax
          } else if (
            ['daily', 'weekly', 'monthly'].includes(
              resultData?.params?.paymentLimit || '',
            )
          ) {
            errorCodeFlote = 117.6
          }
        }
        api.dispatch(setRedirectUrl(`/error/${errorCodeFlote}`))
      } else if (CriticalErrors.includes(errorCode)) {
        api.dispatch(setRedirectUrl(`/error/${errorCode}`))
      }
    }
  }

  return result
}

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: 900,
  tagTypes: [
    'Balance',
    'User',
    'Limits',
    'DepositTransactions',
    'WithdrawTransactions',
    'GameTransactions',
    'PromoTransactions',
    'RecentGame',
    'FavouriteGame',
    'Bonuses',
    'PaymentActivity',
    'GameSearch',
    'RealityCheck',
    'Vouchers',
    'Token',
    'PaymentMethods',
    'PreferredWithdrawalMethod',
  ],
  endpoints: () => ({}),
})

const cms = createApi({
  reducerPath: 'cms',
  refetchOnMountOrArgChange: 900,
  baseQuery: baseQueryCMS,
  tagTypes: [],
  endpoints: () => ({}),
})

const cloudflare = createApi({
  reducerPath: 'cloudflare',
  refetchOnMountOrArgChange: 900,
  baseQuery: baseQueryCloudflare,
  tagTypes: [],
  endpoints: () => ({}),
})

const next = createApi({
  reducerPath: 'next',
  refetchOnMountOrArgChange: true, // Never cache api calls
  baseQuery: baseQueryNext,
  tagTypes: [],
  endpoints: () => ({}),
})

const fastTrack = createApi({
  reducerPath: 'fastTrack',
  refetchOnMountOrArgChange: 900,
  baseQuery: baseQueryFastTrack,
  tagTypes: [],
  endpoints: () => ({}),
})

const fusionUrl = createApi({
  reducerPath: 'fusionUrl',
  refetchOnMountOrArgChange: 900,
  baseQuery: baseQueryFastTrackFusionUrl,
  tagTypes: ['Notifications', 'Notification', 'Auth'],
  endpoints: () => ({}),
})

const redTiger = createApi({
  reducerPath: 'redTiger',
  refetchOnMountOrArgChange: 900,
  baseQuery: baseQueryRedtiger,
  tagTypes: ['Jackpots'],
  endpoints: () => ({}),
})

export { api, cloudflare, cms, fastTrack, fusionUrl, next, redTiger }
